<template>
    <div>
        <b-sidebar id="specialReturn" aria-labelledby="sidebar-no-header-title" no-header width="50rem" backdrop style="direction:ltr" right :title="lang.invoice_return" shadow >
            <template #default="{ hide }">
            <v-form
                ref="forinv" 
                lazy-validation
            >
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span>{{lang.invoice_return}}</span>
                    </div>
                    <div id="HideCMecx" @click="hide" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer;width:100px;text-align:center;" class="btn-danger btn-sm">
                        <span>{{lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <v-row :style="`direction:`+lang.dir+`;`" class="m-2 ps-3 ">
                    <v-row>
                        <v-col cols="12" md="8">
                            <label>{{lang.item_name}}</label>
                            <b-input-group>
                                <b-form-select v-model="item_name" :options="itemsRows" @change="getPrice()" class="selborder" /> 
                                <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                                    <i class="fas fa-arrow-down"></i>
                                </b-input-group-append>
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="4">
                            <label>{{lang.return_total_price}}</label>
                            <b-form-input v-model="total" class="inborder"></b-form-input>
                        </v-col>
                    </v-row>
                </v-row>
            </v-form>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="returnit();" variant="danger" class="ma-2" style="width:150px;">{{lang.invoice_return}}</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            total: 0,
            item_name: '',
            invid: 0,
            return_full_money:0,
            invoice: {},
            returncase: '',
            payMethod:1,
            paytypes:[],
            items: []
        }
    },
    computed:{
        lang: function(){
            // // console.log("look",this.invoice);
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        itemsRows: function(){
            let t = [];
            for(let i=0;i<this.items.length;i++){
                t.push({
                    text: this.items[i].item_name,
                    value: this.items[i].id
                })
            }
            return t;
        }
    },
    created() {
        this.getPyamentType();
    },
    methods: {
        getPrice(){
            for(let i=0;i<this.items.length;i++){
                if(this.items[i].id == this.item_name){
                    this.total = this.items[i].ftotal;
                }
            }
        },
        getItems(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getInvoiceItems');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.invid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                this.items = res.results.data;
            })
        },
        getPyamentType(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                
                for(let i=0;i<res.results.data.length;i++){
                    // // console.log(res);
                    this.paytypes.push({
                        text: this.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                        value: res.results.data[i].id
                    })
                }
                // this.paytypeOp = t;

                this.payMethod = res.results.data[0].id;
            })
        },
        returnit(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('type','returnMySpeInvoice');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[invid]',this.invid);
            post.append('data[full_name]',this.invoice.full_name);
            post.append('data[mobile]',this.invoice.mobile);
            post.append('data[total]',this.total);
            post.append('data[item_name]',this.item_name);
            // for(let i=0;i<this.selItems.length;i++){
            //     post.append('data[items][]',this.selItems[i].id)
            // }
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // // console.log(res);
                // // console.log("lll",res);
                window.open("../api/print.php?retid="+res.results.data,"_blank");
                document.getElementById('HideCMecx').click();
                this.$parent.hideMe();
                this.$parent.$parent.getCurrentCards();
            }).then(() => {
               //this.$router.push({path: "/invoicelist"})
            })
        }
    },
}
</script>